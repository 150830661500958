import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  USERNAME_REGEX,
  USERNAME_LENGTH_REGEX,
  USERNAME_CONTENTS_REGEX,
  PWD_REGEX,
  PWD_LENGTH_REGEX,
  PWD_LOWERCASE_REGEX,
  PWD_UPPERCASE_REGEX,
  PWD_NUMBER_REGEX,
  PWD_SPECIAL_REGEX,
  PWD_EXACT_MATCH_REGEX,
} from "../../../config/regex";
import Filter from "bad-words";
import isEmail from "validator/lib/isEmail";
import ReCAPTCHA from "react-google-recaptcha";
import { clearNotification } from "../../../components/shared/AppLayout/notification/notificationSlice";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import GoogleAuth from "../GoogleAuth";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";
import useTitle from "../../../hooks/useTitle";
import scrollAppToTop from "../../../utils/scrollAppToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import InputRequirementCheckMsg from "../../../components/shared/InputRequirementCheckMsg/InputRequirementCheckMsg";

import styles from "./SignUp.module.css";
import { AuthContext } from "../AuthContext";

const filter = new Filter();

const SignUp = () => {
  useTitle("Sign Up");

  const { isLoading, signUp } = useContext(AuthContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errRef = useRef();
  const usernameRef = useRef();
  const emailRef = useRef();
  const recaptchaRef = useRef();

  const [errField, setErrField] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [consent, setConsent] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [missingRecaptcha, setMissingRecaptcha] = useState(false);

  useEffect(() => {
    if (errMsg) {
      errRef.current.focus();
    }
  }, [errMsg]);

  useEffect(() => {
    if (errField) {
      switch (errField) {
        case "username":
          usernameRef.current.focus();
          break;
        case "email":
          emailRef.current.focus();
          break;
        case "recaptcha":
          recaptchaRef.current.focus();
          break;
        default:
          break;
      }
    }
  }, [errField]);

  useEffect(() => {
    setValidUsername(
      USERNAME_REGEX.test(username) && !filter.isProfane(username)
    );
  }, [username]);

  useEffect(() => {
    setValidEmail(isEmail(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setValidConfirmPassword(validPassword && password === confirmPassword);
  }, [validPassword, password, confirmPassword]);

  useEffect(() => {
    if (recaptchaValue) setMissingRecaptcha(false);
  }, [recaptchaValue, errField]);

  const handleCaptcha = (response) => {
    if (errField === "recaptcha") {
      setErrMsg("");
      setErrField("");
    }
    setRecaptchaValue(response);
  };

  const handleUsernameInput = (e) => {
    if (errField === "username") {
      setErrMsg("");
      setErrField("");
    }
    setUsername(e.target.value);
  };

  const handleEmailInput = (e) => {
    if (errField === "email") {
      setErrMsg("");
      setErrField("");
    }
    setEmail(e.target.value);
  };

  // handle submit
  const canSubmit =
    [
      validUsername,
      validEmail,
      validPassword,
      validConfirmPassword,
      consent,
      recaptchaValue,
      !errField,
    ].every(Boolean) && !isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit) {
      try {
        await signUp(username, email, password);
        setErrMsg("");
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setConsent(false);
        navigate("/login");
      } catch (err) {
        console.log(err.data?.messageVerbose);
        setErrMsg(err.data?.messageVerbose || err.data?.message);
        setErrField(err.data?.errorField);
      }
    } else if (!recaptchaValue) {
      setMissingRecaptcha(true);
      setErrField("recaptcha");
      setErrMsg("please verify you are human");
    } else {
      dispatch(clearNotification());
    }
  };

  let content;

  if (isLoading) {
    scrollAppToTop();
    content = <LoadMsg msg="loading" size="2x" />;
  } else {
    content = (
      <>
        <h2 className={styles.heading}>
          Sign Up for <span className={styles.free}>Free</span>
        </h2>

        <GoogleAuth label="Continue with Google" />

        <div className="divider">or</div>

        {errMsg && (
          <FormErrMsg
            setErrMsg={setErrMsg}
            elements={[
              <p ref={errRef} aria-live="assertive" role="alert">
                {errMsg}
              </p>,
            ]}
          />
        )}

        <form className="form" onSubmit={handleSubmit}>
          {/* username */}
          <div className="form__col">
            <label className="form__label" htmlFor="username">
              Username:
            </label>
            <p className="form__requirements">
              3-20 characters, letters & numbers only
            </p>
            <div className="form__row">
              <input
                className={`form__input
                ${
                  errField === "username"
                    ? "form__input--invalid"
                    : username && validUsername
                    ? "form__input--valid"
                    : username && !validUsername
                    ? "form__input--invalid"
                    : ""
                }
                `}
                type="text"
                id="username"
                value={username}
                onChange={handleUsernameInput}
                autoComplete="off"
                required
                tabIndex="1"
                ref={usernameRef}
              />
            </div>
            <InputRequirementCheckMsg
              value={username}
              regex={USERNAME_LENGTH_REGEX}
              requirement={"3-20 characters"}
            />
            <InputRequirementCheckMsg
              value={username}
              regex={USERNAME_CONTENTS_REGEX}
              requirement={"letters & numbers only"}
            />
          </div>

          {/* email */}
          <div className="form__col">
            <label className="form__label" htmlFor="email">
              Email:
            </label>
            <p className="form__requirements">
              valid email required (e.g., someone@example.com)
            </p>
            <div className="form__row">
              <input
                className={`form__input
                ${
                  errField === "email"
                    ? "form__input--invalid"
                    : email && validEmail
                    ? "form__input--valid"
                    : email && !validEmail
                    ? "form__input--invalid"
                    : ""
                }
                `}
                type="text"
                id="email"
                value={email}
                onChange={handleEmailInput}
                autoComplete="off"
                required
                tabIndex="2"
                ref={emailRef}
              />
            </div>
            <p className={styles.emailVerification}>
              Check your inbox! A verification link will be sent upon account
              creation.
            </p>
          </div>

          {/* password */}
          <div className="form__col">
            <label className="form__label" htmlFor="password">
              Password:
            </label>
            <p className="form__requirements">
              8-64 characters, must include: uppercase & lowercase letter,
              number, & special character
            </p>
            <div className="form__row relative">
              <input
                className={`form__input 
              ${
                password && validPassword
                  ? "form__input--valid"
                  : password && !validPassword
                  ? "form__input--invalid"
                  : ""
              }
              `}
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
                tabIndex="3"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="form__showPassword"
                title={showPassword ? "Hide password" : "Show password"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <InputRequirementCheckMsg
              value={password}
              regex={PWD_LENGTH_REGEX}
              requirement={"8-64 characters"}
            />
            <InputRequirementCheckMsg
              value={password}
              regex={PWD_LOWERCASE_REGEX}
              requirement={"at least 1 lowercase letter"}
            />
            <InputRequirementCheckMsg
              value={password}
              regex={PWD_UPPERCASE_REGEX}
              requirement={"at least 1 uppercase letter"}
            />
            <InputRequirementCheckMsg
              value={password}
              regex={PWD_NUMBER_REGEX}
              requirement={"at least 1 number"}
            />
            <InputRequirementCheckMsg
              value={password}
              regex={PWD_SPECIAL_REGEX}
              requirement={"at least 1 special character"}
            />
          </div>

          {/* confirm password */}
          <div className="form__col">
            <label className="form__label" htmlFor="confirmPassword">
              Confirm password:
            </label>
            <p className="form__requirements">passwords must match exactly</p>
            <div className="form__row relative">
              <input
                className={`form__input 
              ${
                confirmPassword && validConfirmPassword
                  ? "form__input--valid"
                  : confirmPassword && !validConfirmPassword
                  ? "form__input--invalid"
                  : ""
              }
              `}
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="off"
                required
                tabIndex="4"
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="form__showPassword"
                title={showConfirmPassword ? "Hide password" : "Show password"}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            <InputRequirementCheckMsg
              value={confirmPassword}
              regex={PWD_EXACT_MATCH_REGEX(password)}
              requirement={"exact match"}
            />
          </div>

          {/* consent */}
          <div className={styles.consentContainer}>
            <label
              htmlFor="consent"
              className={`form__label ${styles.consent}`}
            >
              <input
                type="checkbox"
                className="form__checkbox"
                id="consent"
                onChange={() => setConsent(!consent)}
                checked={consent}
                tabIndex="5"
                required
              />{" "}
              <div className={styles.consentText}>
                I agree to the
                <a
                  href="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.termsOfService}
                >
                  Terms of Service
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    style={{ fontSize: "0.666rem" }}
                  />
                </a>
              </div>
            </label>
          </div>

          {/* reCAPTCHA */}
          <div className={styles.recaptchaContainer}>
            <div
              className={`${styles.recaptcha} ${
                missingRecaptcha ? styles.missingRecaptcha : ``
              }`}
              ref={recaptchaRef}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleCaptcha}
                tabIndex="6"
                size="normal"
                theme="dark"
              />
            </div>
          </div>

          {/* submit button by default since only 1 button in this form */}
          <button
            className={
              canSubmit ? "form__submitButton" : "form__submitButton--disabled"
            }
            tabIndex="7"
          >
            Create Account
          </button>
        </form>

        <p className={styles.login}>
          Have an account?{" "}
          <Link to="/login" className={styles.link} tabIndex="7">
            Login
          </Link>
        </p>
      </>
    );
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default SignUp;
